


















import { Component, Vue } from "vue-property-decorator";
import gql from 'graphql-tag'
import VueMarkdownIt from "vue-markdown-it";

const AppProps = Vue.extend({
  props: {},
  data: function(){
      return {
        to: {}
      }
  },
  mounted() {

  },
  apollo: {
    to: {
      // GraphQL Query
      query: gql`
        query{
          to {
            text
            text_ru
            text_es
            text_pt
          }
        }
    `,
    },
  },
  metaInfo: {
    meta: [
      {
        vmid: "description",
        name: "description",
        content: `Important Terms of Service for the Bitcoin Games online casino - Registered Company - Accepted Currencies - Governing Laws`,
      },
      {
        vmid: "title",
        name: "title",
        content: `Bitcoin Cash and Bitcoin Games`,
      },
      {
        vmid: "keywords",
        name: "keywords",
        content: `terms of service, tos, bitcoin casino, btc casino, bch casino, crypto casino, bitcoin gambling, bitcoin casino no deposit, crypto casino no deposit, best bitcoin casino, bitcoin games`,
      },
    ],
  },
});
@Component({
  components: {
    VueMarkdownIt
  },
})
export default class Tos extends AppProps {}
